import { gql } from '@apollo/client';

const menuCategoryFields = gql`
  fragment MenuCategoryFields on MenuCategory {
    id
    name
    position
    applyTowardMinimum
    salesCategory {
      id
      name
    }
    taxes {
      id
      enabled
      name
      rate
    }
    fees {
      id
      enabled
      name
      rate
    }
  }
`;
const menuItemFields = gql`
  fragment MenuItemFields on MenuItem {
    name
    details
    detailsHtml
    displayOnGuestMenu
    displayOnExpressBook
    quantitiesOnExtrasEnabled
    quantitiesEditableByGuest
    price
    unit
    image
    addons {
      id
      name
      price
    }
    choices {
      id
      name
      limit
      options {
        id
        name
        price
      }
    }
    taxes {
      id
      enabled
      name
      rate
    }
    fees {
      id
      enabled
      name
      rate
    }
    menuCategory {
      id
      name
    }
  }
`;

const menuFields = gql`
  fragment MenuFields on Menu {
    id
    name
    pdf
    menuCategories {
      id
      ...MenuCategoryFields
    }
    salesCategories {
      id
      name
    }
    menuItems {
      id
      ...MenuItemFields
    }
  }
  ${menuItemFields}
  ${menuCategoryFields}
`;

const venuesQuery = gql`
  query ProviderVenues($ids: [ID!]) {
    venues(ids: $ids) {
      id
      name
      internalName
      canUpdate {
        value
      }
      canListFees {
        value
      }
      canListTaxes {
        value
      }
      canListReports {
        value
      }
      canListMessages {
        value
      }
      canListPayments {
        value
      }
      slug
      email
      image
      images {
        id
        url
        filename
        thumbnail
      }
      customerStartedAt
      banner
      description
      multiDayEnabled
      settings
      timezone
      currency
      websiteUrl
      defaultPaymentProvider
      eventStartOffset
      eventEndOffset
      defaultDepositRate
      defaultDepositAmount
      emailApiTockAddress
      emailApiTheKnotAddress
      emailApiWeddingWireAddress
      emailApiOpenTableAddress
      basicFeaturesEnabled
      proFeaturesEnabled
      premiumFeaturesEnabled
      achEnabled
      showPvBranding
      defaultPaymentMethodMechanism
      widgetInstanceId
      widgetContactFormRedirectUrl
      gtmId
      paymentsEnabled
      merriId
      prismmId
      featureScope {
        id
        achEnabled
        basicFeaturesEnabled
        proFeaturesEnabled
        premiumFeaturesEnabled
      }
      defaultMinimumAmount
      helloLeadFormPath
      helloLeadFormUrl
      qrcodePngUrl
      spaceGraph
      stripeAccount {
        id
        name
        stripeAccountId
      }
      squareAccount {
        id
        squareMerchantId
      }
      squareLocationId
      setup
      minGroupSize
      defaultMinimumEnabled
      defaultDiscountEnabled
      autoCancelStaleLeads
      contactFormBackgroundColor
      contactFormButtonColor
      createdAt
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
      organization {
        id
        name
        discount
        stripeCardDetail
        billingEmail
      }
      venueMemberships {
        id
        user {
          id
          email
          firstName
          lastName
          fullName
        }
      }
      spaces {
        id
        name
        seated
        standing
        ignoreConflicts
        displayColor
      }
      subscription {
        id
        planDefinitionId
        planName
        tier
        status
        billingInterval
        monthlyRate
        cardApplicationFeeRate
        achApplicationFeeRate
        stripeSourceId
        endDate
        lockOut
      }
      defaultEventOwner {
        id
      }
      expressBookSettings {
        id
        enabled
      }
      featureFlags
      reserveRestaurantId
      venueType {
        id
      }
    }
  }
`;

const contractItemFields = gql`
  fragment ContractItemFields on ContractItem {
    id
    name
    index
    price
    unit
    displayOnGuestMenu
    basePrice
    quantity
    total
    details
    description
    descriptionHtml
    image
    addons {
      id
      name
      price
    }
    choices {
      id
      name
      limit
      options {
        id
        name
        price
      }
    }
    contractTaxes {
      id
      enabled
      name
      rate
      taxId
    }
    contractFees {
      id
      enabled
      name
      rate
      feeId
    }
    optionIds
    addonIds
    menuCategory {
      id
      name
      position
    }
    quantitiesOnExtrasEnabled
    quantitiesEditableByGuest
    addonQuantities
    optionQuantities
  }
`;

const contractFields = gql`
  fragment ContractFields on Contract {
    uuid
    totals
    discountEnabled
    discountAmount
    minimumEnabled
    minimumAmount
    depositRate
    depositAmount
    action
    signature
    locked
    lockCondition
    paymentMethodMechanism
    otherPaymentAmount
    foodAndBeverageMinimumId
    venuePolicyHtml
    canUpdate {
      value
    }
    venuePolicy {
      id
      name
      body
    }
    contractTaxes {
      id
      rate
      enabled
      applyToMinimum
      name
      overrideAmount
      tax {
        id
        name
        rate
        enabled
        applyToMinimum
      }
    }
    contractFees {
      id
      rate
      enabled
      applyToMinimum
      name
      overrideAmount
      contractTaxes {
        id
        rate
        enabled
        name
      }
      fee {
        id
        name
        rate
      }
    }
    contractItems {
      id
      ...ContractItemFields
    }
  }
  ${contractItemFields}
`;

const eventPaymentFields = gql`
  fragment EventPaymentFields on Payment {
    id
    type
    name
    kind
    amount
    refundAmount
    paidAt
    scheduledAt
    state
    amountMinusRefundAmount
    refunds {
      id
      amount
      reason
      createdAt
    }
  }
`;

const eventFields = gql`
  fragment EventFields on Event {
    name
    startDate
    startOffset
    endDate
    endOffset
    tourStartDate
    tourStartOffset
    tourEndOffset
    rehearsalStartDate
    rehearsalStartOffset
    rehearsalEndOffset
    status
    eventTypeName
    groupSize
    budgetOrContractAmount
    budget
    completedAt
    canceledAt
    discardedAt
    refundedAt
    customFieldValues
    smartFieldValues
    showProposalDeposit
    utmSource
    utmCampaign
    paymentProvider
    multiDayEnabled
    internalNoteHtml
    proposalNoteHtml
    interpolatedInternalNoteHtml
    interpolatedProposalNoteHtml
    merriId
    prismmId
    origin
    canUpdate {
      value
    }
    canShowEventBudget {
      value
    }
    user {
      id
      email
      fullName
    }
    seniorSpace {
      id
      name
      displayColor
    }
    spaces {
      id
      name
      displayColor
    }
    planner {
      id
      firstName
      lastName
      fullName
      email
      phone
      org
      pastEventCount
      notes
      account {
        id
        name
        address {
          id
          streetAddress1
          streetAddress2
          city
          state
          zip
        }
      }
      paymentMethods {
        id
        type
        last4
        brand
        mechanism
        displayLabel
        paymentProvider
      }
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        zip
      }
    }
    paymentMethods {
      id
      type
      last4
      brand
      mechanism
      displayLabel
      paymentProvider
    }
    depositPayment {
      ...EventPaymentFields
    }
    remainingBalancePayment {
      ...EventPaymentFields
    }
    payments {
      ...EventPaymentFields
    }
    venue {
      id
      name
      internalName
      email
      image
      banner
      defaultMinimumEnabled
      merriId
      prismmId
      featureScope {
        id
        premiumFeaturesEnabled
        proFeaturesEnabled
        basicFeaturesEnabled
        achEnabled
      }
      currency
      taxes {
        id
        enabled
        name
        rate
        applyToMinimum
      }
      fees {
        id
        enabled
        name
        rate
        applyToMinimum
      }
      users {
        id
        firstName
        lastName
        fullName
      }
      squareLocationId
    }
  }
  ${eventPaymentFields}
`;

const eventQuery = gql`
  query Event2($id: ID!) {
    event(id: $id) {
      id
      ...EventFields
      spaceAvailability
      contract {
        id
        ...ContractFields
      }
      reserveReservationId
      reserveLastUpdatedAt
      reserveErrorMessage
    }
  }
  ${eventFields}
  ${contractFields}
`;

export {
  contractItemFields,
  contractFields,
  eventFields,
  eventQuery,
  menuFields,
  menuItemFields,
  menuCategoryFields,
  venuesQuery,
};
