import React from 'react';
import { Button, Drawer, Box, useTheme } from '@mui/material';
import EventDrawer from './EventDrawer';
import EventProvider from '../providers/event';

export const EventPreviewDrawer = ({
  isDrawerOpen,
  onClosePreview,
  onEventUpdate,
  eventId,
}: {
  isDrawerOpen: boolean;
  onClosePreview: () => void;
  onEventUpdate?: () => {};
  eventId: string;
}) => {
  const theme = useTheme();

  return (
    <EventProvider eventId={eventId}>
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        onClose={onClosePreview}
        ModalProps={{
          disableEnforceFocus: true,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 2,
            alignItems: 'center',
            gap: 1,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button onClick={onClosePreview} variant="outlined" color="secondary">
            Close
          </Button>
          <Button
            data-cy="event-preview-drawer-open-button"
            color="secondary"
            href={`/events/${eventId}`}
            variant="contained"
          >
            Open
          </Button>
        </Box>

        <EventDrawer onEventUpdate={onEventUpdate} />
      </Drawer>
    </EventProvider>
  );
};
