import React, { useEffect, useState } from 'react';
import { Divider, Grid, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import first from 'lodash/first';
import { AccountInput, SidebarDrawer } from '@pv/common/components';
import {
  useCreatePlannerMutation,
  useUpdatePlannerMutation,
} from '@pv/common/graphql';
import { useVenues } from '../providers/venues';
import { PlannerEventStats } from './PlannerEventStats';
import { gql, useQuery } from '@apollo/client';
import {
  PlannerQuery,
  PlannerQueryVariables,
} from '@pv/common/graphql/operations';
import { CreatePlannerInput } from '@pv/common/graphql/types';
import { eventContactsQuery } from '../hooks/useEventContactsQuery';
import {
  EventContactsQuery,
  EventContactsQueryVariables,
} from '@pv/common/graphql/operations';
import { useCountries } from '../hooks/useCountries';
import { useStates } from '../hooks/useStates';
import { AddressFields } from './AddressFields';
import { PhoneField } from './PhoneField';

const plannerQuery = gql`
  query PlannerQuery($id: ID) {
    planner(id: $id) {
      id
      firstName
      lastName
      email
      phone
      account {
        id
        name
      }
      notes
      title
      address {
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
    }
  }
`;

interface PlannerDrawerProps {
  plannerId?: string;
  open: boolean;
  onClose: () => void;
  afterMutate?: () => void;
  readOnly?: boolean;
  prefilledFirstName?: string;
  eventId?: string;
}

export const PlannerDrawer = ({
  plannerId,
  open,
  onClose,
  afterMutate,
  readOnly = false,
  prefilledFirstName = '',
  eventId,
}: PlannerDrawerProps) => {
  const { venues, venueIds } = useVenues();
  const venue = first(venues);
  const organizationId = venue?.organization?.id;
  const venueId = first(venueIds);

  const [firstName, setFirstName] = useState(prefilledFirstName);
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [accountName, setAccountName] = useState('');
  const [notes, setNotes] = useState('');
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState({
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });
  const { data } = useQuery<PlannerQuery, PlannerQueryVariables>(plannerQuery, {
    variables: {
      id: plannerId,
    },
    skip: !plannerId || !open,
  });

  const plannerData = data?.planner;

  useEffect(() => {
    const setStateForPlanner = () => {
      setFirstName(plannerData?.firstName || prefilledFirstName);
      setLastName(plannerData?.lastName || '');
      setEmail(plannerData?.email || '');
      setPhone(plannerData?.phone || '');
      setAccountName(plannerData?.account?.name || '');
      setNotes(plannerData?.notes || '');
      setTitle(plannerData?.title || '');
      setAddress({
        streetAddress1: plannerData?.address?.streetAddress1 || '',
        streetAddress2: plannerData?.address?.streetAddress2 || '',
        city: plannerData?.address?.city || '',
        state: plannerData?.address?.state || '',
        zip: plannerData?.address?.zip || '',
        country: plannerData?.address?.country || '',
      });
    };
    setStateForPlanner();
  }, [data, open, plannerData, prefilledFirstName]);

  const countries = useCountries();
  const states = useStates(address);

  const [createPlanner, { loading: createLoading }] = useCreatePlannerMutation({
    onCompleted: () => {
      onClose();
      enqueueSnackbar('Created Contact', { variant: 'success' });
      if (afterMutate) {
        afterMutate();
      }
    },
    update: (cache, { data }) => {
      if (!data || !data.createPlanner) {
        return;
      }

      if (eventId) {
        const existingContacts = cache.readQuery<
          EventContactsQuery,
          EventContactsQueryVariables
        >({
          query: eventContactsQuery,
          variables: { eventId },
        });

        if (existingContacts?.event?.additionalContacts) {
          cache.writeQuery({
            query: eventContactsQuery,
            variables: { eventId },
            data: {
              event: {
                ...existingContacts.event,
                additionalContacts: [
                  ...existingContacts.event.additionalContacts,
                  data.createPlanner.planner,
                ],
              },
            },
          });
        }
      }
    },
  });

  const [updatePlanner, { loading: updateLoading }] = useUpdatePlannerMutation({
    onCompleted: () => {
      onClose();
      enqueueSnackbar('Updated Contact', { variant: 'success' });
      if (afterMutate) {
        afterMutate();
      }
    },
  });

  const onSave = () => {
    if (plannerId) {
      const input = {
        firstName,
        lastName,
        email,
        phone,
        notes,
        accountName,
        title,
        address: {
          streetAddress1: address.streetAddress1,
          streetAddress2: address.streetAddress2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          country: address.country,
        },
        id: plannerId,
      };
      updatePlanner({ variables: { input } });
    } else if (venueId) {
      const input: CreatePlannerInput = {
        firstName,
        lastName,
        email,
        phone,
        notes,
        accountName,
        venueId,
        title,
        address: {
          streetAddress1: address.streetAddress1,
          streetAddress2: address.streetAddress2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          country: address.country,
        },
      };
      if (eventId) {
        input.eventId = eventId;
      }
      createPlanner({ variables: { input } });
    }
  };

  const loading = createLoading || updateLoading;

  return (
    <SidebarDrawer
      open={open}
      onClose={onClose}
      title={plannerId ? 'Edit Contact' : 'New Contact'}
      onSave={onSave}
      loading={loading}
    >
      <Grid container spacing={2} data-cy="event-drawer-contact-edit">
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            value={firstName}
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            label="First Name"
            disabled={readOnly}
            autoFocus={!!prefilledFirstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            value={lastName}
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            label="Last Name"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            label="Title"
            disabled={readOnly}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AccountInput
            organizationId={organizationId}
            value={accountName}
            name="account"
            onChange={setAccountName}
            label="Account"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneField
            fullWidth
            value={phone}
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            label="Phone"
            type="tel"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <AddressFields
          address={address}
          setAddress={setAddress}
          states={states}
          countries={countries}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            minRows={6}
            value={notes}
            name="notes"
            onChange={(e) => setNotes(e.target.value)}
            label="Notes"
            disabled={readOnly}
          />
        </Grid>
      </Grid>
      {plannerId && (
        <>
          <PlannerEventStats plannerId={plannerId} />
        </>
      )}
    </SidebarDrawer>
  );
};

export default PlannerDrawer;
