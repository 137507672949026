import {
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Box,
  Paper,
  Divider,
} from '@mui/material';

import { MoreVertical } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import {
  Fields,
  FullScreen,
  DeleteEventConfirmModal,
} from '@pv/common/components';
import { useMutation } from '@pv/common/hooks';
import each from 'lodash/each';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useEvent } from '../../providers/event';
import CustomFieldArea from '../../components/CustomFieldArea';
import { EventStatusChip } from '../EventStatusChip';
import UtmTracking from '../../components/UtmTracking';
import StatusChip from '../../components/StatusChip';
import { ExpressBookChip } from '../../components/ExpressBookChip';
import { AvailabilityCheckedChip } from '../../components/AvailabilityCheckedChip';
import { ContactArea } from './ContactArea';
import EventInfoArea from './EventInfoArea';
import {
  removeEventRehearsalMutation,
  removeEventTourMutation,
  updateEventMutation,
} from './graphql';
import RehearsalInfo from './RehearsalInfo';
import TourInfo from './TourInfo';
import GreyDot from '../../components/GreyDot';
import {
  SmallTextButton,
  SectionContainer,
  ButtonRowContainer,
} from './components';
import { AutoSave } from '../../components/AutoSave';
import { deleteEventMutation } from '@pv/common/graphql';
import { CreateEventModal } from '../CreateEventModal';
import { ReserveInfo } from './ReserveInfo';
const drawerWidth = 370;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw',
      width: '100vw',
      maxWidth: '100vw',
    },
  },
}));

export const LabelField = ({ label, minWidth = 100, children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ flexShrink: 1, minWidth }}>
      <InputLabel>
        <strong>{label}</strong>
      </InputLabel>
    </div>
    <div style={{ flexGrow: 1, overflow: 'hidden' }}>{children}</div>
  </div>
);

const EventDrawer = ({
  allowDayView = false,
  onEventUpdate = () => {},
  onDayViewClick = () => {},
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { event, refetchEvent, eventVenue } = useEvent();
  const { id, canUpdate } = event;
  const canUpdateEvent = canUpdate?.value;
  const premiumFeaturesEnabled = useMemo(
    () => event?.venue?.featureScope?.premiumFeaturesEnabled,
    [event]
  );

  const [resetHack, setResetHack] = useState(uuidv4());
  const [moreAnchor, setMoreAnchor] = useState(null);
  const [showTour, setShowTour] = useState(!!event.tourStartDate);
  const [openDuplicateEventModal, setOpenDuplicateEventModal] = useState(false);
  const [showRehearsal, setShowRehearsal] = useState(
    !!event.rehearsalStartDate
  );

  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [reserveDialogOpen, setReserveDialogOpen] = useState(false);

  const [removeEventRehearsal] = useMutation(removeEventRehearsalMutation);
  const [removeEventTour] = useMutation(removeEventTourMutation);

  const [updateEventImmediately] = useMutation(updateEventMutation, {
    onNoErrorsCompleted: () => {
      if (refetchEvent) {
        refetchEvent();
      }
    },
  });

  const debounce = { leading: false, trailing: true, timeout: 500 };
  const [updateEvent] = useMutation(updateEventMutation, {
    debounce,
    onNoErrorsCompleted: ({ updateEvent }) => onEventUpdate(updateEvent),
    onSomeErrorsCompleted: ({ updateEvent }) => {
      setResetHack(uuidv4());
      each(updateEvent.errors, (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    },
  });

  const save = useCallback(
    (update = {}, optimisticUpdate = {}) => {
      const input = {
        ...update,
        id: event.id,
      };
      const variables = { input };
      const optimisticResponse = {
        updateEvent: {
          event: {
            __typename: 'Event',
            ...event,
            id: event.id,
            ...optimisticUpdate,
          },
          errors: [],
        },
      };
      if (canUpdateEvent) updateEvent({ variables, optimisticResponse });
    },
    [event, updateEvent, canUpdateEvent]
  );

  const getInitialRange = () => {
    const initialStartDate = new Date();
    const startTimeStart = eventVenue?.eventStartOffset || 600;
    return {
      startDate: initialStartDate,
      endDate: initialStartDate,
      startOffset: startTimeStart,
      endOffset: startTimeStart + 120,
    };
  };

  const onClickAddTour = () => {
    updateEventImmediately({
      variables: {
        input: {
          id,
          tourDateTimeRange: getInitialRange(),
        },
      },
    });
    setShowTour(true);
    setMoreAnchor(null);
  };

  const onClickAddRehearsal = () => {
    updateEventImmediately({
      variables: {
        input: {
          id,
          rehearsalDateTimeRange: getInitialRange(),
        },
      },
    });
    setShowRehearsal(true);
    setMoreAnchor(null);
  };

  const onClickReserveBySpotOn = () => {
    setMoreAnchor(null);
    setReserveDialogOpen(true);
  };

  const [deleteEvent] = useMutation(deleteEventMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar('Event Deleted', { variant: 'info' });
      navigate('/events', { replace: false });
    },
    onSomeErrorsCompleted: ({ deleteEvent: deleteEventResponse }) => {
      each(deleteEventResponse.errors, (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          style: { size: '10px' },
        });
      });
    },
  });

  const onClickDeleteEvent = () => {
    setShowDeleteEventModal(true);
  };

  const onDeleteEvent = () => {
    const variables = { input: { id: event.id } };
    deleteEvent({ variables });
    setShowDeleteEventModal(false);
  };

  const removeRehearsal = () => {
    const variables = { input: { id: event.id } };
    removeEventRehearsal({ variables });
    setShowRehearsal(false);
  };

  const removeTour = () => {
    const variables = { input: { id: event.id } };
    removeEventTour({ variables });
    setShowTour(false);
  };

  const handleUpdateEventStatus = (status) => {
    updateEventImmediately({
      variables: {
        input: {
          id: event.id,
          status: status,
        },
      },
    });
  };

  const onClickDuplicate = () => {
    setOpenDuplicateEventModal(true);
    setMoreAnchor(null);
  };

  const redirectToDuplicatedEvent = (newEvent) => {
    setOpenDuplicateEventModal(false);
    if (newEvent && newEvent.id) {
      navigate(`/events/${newEvent.id}`, { replace: false });
    }
  };

  const useStylesEventName = makeStyles((theme) => ({
    root: {
      padding: 0,
      // when not focused, hide the border
      '&:not(:focus-within)': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.15)',
        },
      },
      '& textarea': {
        margin: 0,
        padding: '6px 8px',
        color: '#222222',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '133.4%',
      },
    },
  }));
  const eventNameClasses = useStylesEventName();

  const showReserveMenuItem =
    eventVenue?.reserveRestaurantId || event?.reserveReservationId;

  return (
    <FullScreen style={{ width: '100%' }}>
      {({ height }) => (
        <Paper
          style={{
            minHeight: height,
            border: '1px solid rgba(0, 0, 0, 0.05)',
            paddingTop: '6px',
          }}
          square
          className={classes.paper}
          variant="permanent"
          anchor="left"
          data-cy="event-drawer"
        >
          <DeleteEventConfirmModal
            onConfirm={onDeleteEvent}
            open={showDeleteEventModal}
            onClose={() => setShowDeleteEventModal(false)}
          />
          <SectionContainer>
            <Box>
              {event.origin === 'express_book' && (
                <Box marginBottom={'12px'}>
                  <ExpressBookChip />
                </Box>
              )}
              {event.origin === 'availability_check' && (
                <Box marginBottom={'12px'}>
                  <AvailabilityCheckedChip />
                </Box>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'hidden',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{ flexGrow: 1, overflow: 'hidden' }}
                  data-cy="event-page-name"
                >
                  <AutoSave
                    value={event.name}
                    onSave={(value) => {
                      save({ eventName: value }, { name: value });
                    }}
                    renderField={(value, onChange) => (
                      <Fields.PvTextField
                        variant={canUpdateEvent ? 'outlined' : 'standard'}
                        id="eventName"
                        disabled={!canUpdateEvent}
                        disableUnderline={!canUpdateEvent}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={4}
                        placeholder="Add Event Title"
                        aria-label="Event Name"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            onChange(e.target.value);
                          }
                        }}
                        data-cy="event-page-name-edit"
                        InputProps={{
                          classes: eventNameClasses,
                        }}
                      />
                    )}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {canUpdateEvent && (
                    <div style={{ flexShrink: 1 }}>
                      <IconButton
                        data-cy="event-menu"
                        className="more-menu"
                        onClick={(e) => setMoreAnchor(e.currentTarget)}
                        size="small"
                        sx={{
                          color: '#222222',
                        }}
                      >
                        <MoreVertical />
                      </IconButton>
                      <Menu
                        anchorEl={moreAnchor}
                        open={Boolean(moreAnchor)}
                        onClose={() => setMoreAnchor(null)}
                      >
                        {showReserveMenuItem && (
                          <MenuItem button onClick={onClickReserveBySpotOn}>
                            Reserve by SpotOn
                          </MenuItem>
                        )}
                        <MenuItem button onClick={onClickDuplicate}>
                          Duplicate Event
                        </MenuItem>
                        <MenuItem
                          button
                          onClick={onClickAddTour}
                          disabled={showTour}
                        >
                          Add Tour
                        </MenuItem>
                        <MenuItem
                          button
                          onClick={onClickAddRehearsal}
                          disabled={showRehearsal}
                        >
                          Add Rehearsal
                        </MenuItem>
                        <MenuItem
                          button
                          onClick={onClickDeleteEvent}
                          sx={{
                            color: 'PVRed',
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
            </Box>
            <Box>
              {canUpdateEvent ? (
                <EventStatusChip
                  onChange={handleUpdateEventStatus}
                  event={event}
                  status={event.status}
                />
              ) : (
                <StatusChip status={event.status} fullWidth size="large" />
              )}
            </Box>
          </SectionContainer>
          <Divider />
          <ContactArea readOnly={!canUpdateEvent} />
          <Divider />
          <EventInfoArea
            resetHack={resetHack}
            onDayViewClick={onDayViewClick}
            allowDayView={allowDayView}
            updateEventImmediately={updateEventImmediately}
            onSave={save}
          />
          {showTour && <TourInfo removeTour={removeTour} onSave={save} />}
          {showRehearsal && (
            <RehearsalInfo removeRehearsal={removeRehearsal} onSave={save} />
          )}
          {canUpdateEvent && (
            <SectionContainer>
              <ButtonRowContainer>
                {showTour ? (
                  <SmallTextButton onClick={removeTour}>
                    Remove Tour
                  </SmallTextButton>
                ) : (
                  <SmallTextButton onClick={onClickAddTour}>
                    Add Tour
                  </SmallTextButton>
                )}
                <GreyDot />
                {showRehearsal ? (
                  <SmallTextButton onClick={removeRehearsal}>
                    Remove Rehearsal
                  </SmallTextButton>
                ) : (
                  <SmallTextButton onClick={onClickAddRehearsal}>
                    Add Rehearsal
                  </SmallTextButton>
                )}
                {showReserveMenuItem && (
                  <SmallTextButton onClick={onClickAddRehearsal}>
                    Add Rehearsal
                  </SmallTextButton>
                )}
              </ButtonRowContainer>
            </SectionContainer>
          )}
          <Divider />
          <CustomFieldArea eventId={event.id} />
          {premiumFeaturesEnabled && (
            <>
              <Divider />
              <SectionContainer>
                <UtmTracking onSave={save} readOnly={!canUpdateEvent} />
              </SectionContainer>
            </>
          )}
          <CreateEventModal
            eventToDuplicate={event}
            open={openDuplicateEventModal}
            onClose={redirectToDuplicatedEvent}
          />
          <ReserveInfo
            event={event}
            open={reserveDialogOpen}
            onClose={() => {
              setReserveDialogOpen(false);
            }}
          />
        </Paper>
      )}
    </FullScreen>
  );
};

export default EventDrawer;
