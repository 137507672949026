import React from 'react';
import { Avatar } from '@mui/material';

import { gql, useQuery } from '@apollo/client';

interface PVAvatarProps {
  imageUrl?: string;
  fullName?: string;
  height?: number;
  backgroundColor?: string;
}

export const PVAvatar = ({
  imageUrl,
  fullName,
  height = 35,
  backgroundColor = 'primary.main',
}: PVAvatarProps) => {
  const initials = React.useMemo(() => {
    if (!fullName) {
      return '';
    }
    const names = fullName.split(' ');
    if (names.length > 2) {
      return names[0][0];
    }
    return fullName
      .split(' ')
      .map((name) => name[0])
      .join('');
  }, [fullName]);

  return (
    <Avatar
      src={imageUrl}
      alt={fullName}
      title={fullName}
      sx={{
        height: height,
        width: height,
        fontSize: height * 0.45,
        bgcolor: backgroundColor,
        fontWeight: 500,
        img: {
          objectFit: 'contain',
        },
      }}
    >
      {initials}
    </Avatar>
  );
};

const GET_PLANNER = gql`
  query GetPlanner($id: ID!) {
    planner(id: $id) {
      id
      fullName
      gravatarUrl
    }
  }
`;

interface PlannerAvatarProps {
  plannerId?: string;
  height?: number;
}

export const PlannerAvatar = ({ plannerId, height }: PlannerAvatarProps) => {
  const { data } = useQuery(GET_PLANNER, {
    variables: { id: plannerId },
    skip: !plannerId,
    fetchPolicy: 'cache-first',
  });

  if (!plannerId) {
    return null;
  }

  return (
    <PVAvatar
      imageUrl={data?.planner?.gravatarUrl}
      fullName={data?.planner?.fullName}
      height={height}
      backgroundColor="primary.main"
    />
  );
};

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      image
      gravatarUrl
    }
  }
`;

interface UserAvatarProps {
  userId?: string;
  height?: number;
}

export const UserAvatar = ({ userId, height }: UserAvatarProps) => {
  const { data } = useQuery(GET_USER, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'cache-first',
  });

  if (!userId) {
    return null;
  }

  return (
    <PVAvatar
      imageUrl={data?.user?.image || data?.user?.gravatarUrl}
      fullName={data?.user?.fullName}
      height={height}
      backgroundColor="primary.pink"
    />
  );
};
