import { useQuery } from '@apollo/client';
import find from 'lodash/find';
import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PageLoading } from '@pv/common/components';
import { useUserAuth } from '../providers/auth';
import { useVenues } from './venues';
import { contractFields, eventFields, eventQuery } from './graphql';
import { Event2Query } from '@pv/common/graphql/operations';
export { contractFields, eventFields };

interface EventContextProps {
  event: Event2Query['event'];
  loading: boolean;
  eventVenue: any;
  refetchEvent: () => void;
}
const EventContext = createContext<EventContextProps>({
  event: null,
  loading: false,
  eventVenue: null,
  refetchEvent: () => {},
});

export const useEvent = () => useContext(EventContext);

const EventProvider = ({
  children,
  eventId = '',
}: {
  children: JSX.Element[] | JSX.Element;
  eventId: string;
}) => {
  const params = useParams();
  const { venues } = useVenues();
  const { user } = useUserAuth();
  const { id: routeId } = params;
  const id = routeId || eventId;
  const isValidId = /^[0-9]+$/.test(id);
  const skip = !id || !isValidId || !venues || !user;
  const variables = { id };

  const {
    data,
    refetch: refetchEvent,
    loading,
  } = useQuery<Event2Query>(eventQuery, {
    variables,
    skip,
  });
  const event = data?.event;
  const eventVenue = find(venues, (v) => v.id === event?.venue?.id);

  const memoizedValue = useMemo(
    () => ({
      event: event,
      loading,
      refetchEvent,
      eventVenue,
    }),
    [event, loading, eventVenue, refetchEvent]
  );

  if (loading || !venues || !user) {
    return <PageLoading />;
  }

  return (
    <EventContext.Provider value={memoizedValue}>
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;
